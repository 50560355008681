/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'exclamation': {
    width: 4,
    height: 14,
    viewBox: '0 0 4 14',
    data: '<path pid="0" d="M2.761.5a.5.5 0 01.5.5v4.555c0 .542-.03 1.081-.093 1.617-.047.4-.106.805-.175 1.217a.494.494 0 01-.489.41h-.957a.494.494 0 01-.489-.41C.988 7.977.931 7.57.884 7.172A13.955 13.955 0 01.79 5.555V1a.5.5 0 01.5-.5H2.76zM.5 11.989c0-.212.037-.41.11-.592.08-.183.185-.342.316-.478.136-.135.296-.241.477-.318.182-.082.381-.123.597-.123.21 0 .406.04.588.123.182.077.341.183.477.318.137.136.242.295.316.478.08.182.119.38.119.592 0 .212-.04.412-.12.6a1.41 1.41 0 01-.315.478A1.491 1.491 0 012 13.5a1.491 1.491 0 01-1.074-.433 1.6 1.6 0 01-.315-.477 1.632 1.632 0 01-.111-.601z" _fill="#CE5769"/>'
  }
})
